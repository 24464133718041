export interface IFont {
  fontFamily: string;
  url: string;
}

const defaultFont = {
  fontFamily: 'Arial, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue"',
  url: '',
};

const customFonts = {};

const getFont = (clientId: string): IFont =>
  customFonts[clientId] ? customFonts[clientId] : defaultFont;

export default getFont;
