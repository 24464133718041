import { useCart } from 'react-use-cart';
import { GroupAddonRequestType, Item } from 'src/graphql/generated/operations';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useIngestionType from './ingestion-type';

/**
 * The ICartAddons interface is a map from an item identifier to an array of arrays of GroupAddonRequestType.
 */
export interface ICartAddons {
  [key: string]: GroupAddonRequestType[][];
}

/**
 * ICartComments is a map where the key is an item id and the value is a comment string.
 */
export interface ICartComments {
  [key: string]: string;
}

/**
 * An extension of the Items returned from our API, to cater to react-use-cart.
 */
export interface ICartItem extends Item {
  price: number;
  quantity: number;
  comment?: string;
  itemTotal: number;
}

export interface ICartMetadata {
  /**
   * The addons are stored as a map from item id to the format expected by the api when creating an order.
   */
  addons: ICartAddons;
  /**
   * Comments are a map from item id to the note itself. Only one note per item is allowed.
   */
  comments: ICartComments;
  lastModified?: Dayjs;
  phone?: string;
  table?: string;
}

export interface IPaiditCart {
  setComment: (id: string, comment: string) => void;
  deleteComment: (id: string) => void;
  addItem: (item: Item, addonGroups?: GroupAddonRequestType[]) => void;
  removeItem: (id: string) => void;
  itemQuantity: (id: string) => number;
  updateCartMetadata: (metadata: Partial<ICartMetadata>) => void;
  getAddonsForItem: (id: string) => GroupAddonRequestType[][];
  getAddonPriceForItem: (id: string) => number;
  clearItems: () => void;
  clearAddons: () => void;
  totalItems: number;
  cartTotal: number;
  items: ICartItem[];
  metadata: ICartMetadata;
}

/**
 * Computes the total cost of the provided addons.
 * @param addons an ICartAddons object
 * @returns the total cost of all selected addons
 */
const computeValueOfAllAddons = (addons: ICartAddons): number => {
  const allAddonGroups = Object.values(addons).flat().flat();
  const allAddons = allAddonGroups.map((addon) => addon.addonItems).flat();
  const addonsTotal = allAddons.map((addon) => addon.price).reduce((a, b) => a + b, 0);

  return addonsTotal || 0;
};

/**
 * Computes the total cost of the addons on one item.
 * @param addons an ICartAddons object
 * @param id an item id
 * @returns the total cost of all selected addons
 */
const computeValueOfAddons = (addons: ICartAddons, id: string): number => {
  let addonsTotal;

  try {
    const addonGroupsForItem = addons[id]?.flat().flat() || [];
    const allAddons = addonGroupsForItem.map((addon) => addon.addonItems).flat();
    addonsTotal = allAddons.map((addon) => addon.price).reduce((a, b) => a + b, 0);
  } catch {
    addonsTotal = 0;
  }

  return addonsTotal || 0;
};

/**
 * This is a super slim wrapper on top of react-use-cart so we can apply custom types
 * on the cart contents and cart metadata without having to repeat the type casting all over
 * the code base.
 */
export const usePaiditCart = (): IPaiditCart => {
  const ingestion = useIngestionType();
  const cart = useCart();
  // the Item of react-use-cart accepts any key-value, so it overlaps with ICartItem.
  const items: ICartItem[] = (cart.items || []) as ICartItem[];
  const metadata: ICartMetadata = { addons: {}, comments: {}, ...cart.metadata };
  const [paiditCartTotal, setPaiditCartTotal] = useState<number>(0);
  const { updateCartMetadata, totalItems, cartTotal } = cart;
  const allAddonsTotal = useMemo(() => computeValueOfAllAddons(metadata.addons), [metadata]);

  /**
   * We recompute our own implementation of the cart total any time the cartTotal (based on items)
   * or the addons total changes.
   */
  useEffect(() => {
    setPaiditCartTotal(cartTotal + allAddonsTotal);
  }, [cartTotal, allAddonsTotal]);

  /**
   * Returns the added addons for a specific item.
   * @param id the item id
   * @returns currently added addons for the item
   */
  const getAddonsForItem = useCallback(
    (id: string): GroupAddonRequestType[][] => {
      const addonsCopy = { ...metadata.addons };
      return [...(addonsCopy[id] || [])];
    },
    [metadata],
  );

  const getAddonPriceForItem = useCallback(
    (id: string): number => {
      const addonPriceForItem = computeValueOfAddons({ ...metadata.addons }, id);

      return addonPriceForItem;
    },
    [metadata],
  );

  /**
   * Updates the last modified date for the cart to the current moment.
   */
  const updateLastModified = () => {
    const lastModified = dayjs();
    const newMetadata = { ...metadata };
    newMetadata.lastModified = lastModified;
    updateCartMetadata(newMetadata);
  };

  /**
   * Updates the set of addons for a specific item.
   */
  const updateAddons = (id, addonGroups: GroupAddonRequestType[][]) => {
    const newMetadata = { ...metadata };
    newMetadata.addons[id] = addonGroups;
    updateCartMetadata(newMetadata);
  };

  /**
   * Adds addons to an item.
   */
  const addAddons = (item: Item, addonGroups?: GroupAddonRequestType[]) => {
    const addonGroupsForItem = getAddonsForItem(item.id);
    addonGroupsForItem.push(addonGroups || []);
    updateAddons(item.id, addonGroupsForItem);
  };

  /**
   * Removes the last added addon from an item.
   */
  const removeLastAddon = (id: string) => {
    const addonGroupsForItem = getAddonsForItem(id);
    const newAddonGroupsForItem = addonGroupsForItem.slice(0, addonGroupsForItem.length - 1);
    updateAddons(id, newAddonGroupsForItem);
  };

  /**
   * Fetches the item quantity for an item.
   * @param id an item id
   * @returns a number representing the quantity in the cart.
   */
  const itemQuantity = useCallback(
    (id: string): number => {
      const item = items.find((i) => i.id === id);
      return item ? item.quantity : 0;
    },
    [items],
  );

  /**
   * Adds one of the provided item to the cart. Optionally adds addons as well.
   * If no addons are provided, a blank set of addons will be added to maintain addon order integrity.
   */
  const addItem = (item: Item, addonGroups?: GroupAddonRequestType[]) => {
    const price = item.ingestions.find((i) => i.type === ingestion)?.price;
    const currentQuantity = itemQuantity(item.id);
    if (currentQuantity > 0) {
      cart.updateItemQuantity(item.id, currentQuantity + 1);
    } else {
      cart.addItem({ ...item, price });
    }
    addAddons(item, addonGroups);
    updateLastModified();
  };

  /**
   * Removes one the provided item id from the cart. This also removes the last added addon.
   * @param id item id
   */
  const removeItem = (id: string) => {
    const currentQuantity = itemQuantity(id);
    if (currentQuantity === 1) {
      cart.removeItem(id);
    } else {
      cart.updateItemQuantity(id, currentQuantity - 1);
    }
    removeLastAddon(id);
    updateLastModified();
  };

  /**
   * Removes all addons from the cart.
   */
  const clearAddons = () => {
    updateCartMetadata({ addons: {} });
  };

  /**
   * Removes all items from the cart.
   */
  const clearItems = () => {
    cart.setItems([]);
    updateLastModified();
  };

  /**
   * Adds a comment to the metadata.
   */
  const setComment = (id: string, comment: string) => {
    const comments = { ...metadata.comments };
    comments[id] = comment;
    updateCartMetadata({ comments });
  };

  /**
   * Deletes any comment for the provided item id.
   */
  const deleteComment = (id: string) => {
    const comments = { ...metadata.comments };
    comments[id] = undefined;
    updateCartMetadata({ comments });
  };

  return {
    setComment,
    deleteComment,
    addItem,
    removeItem,
    itemQuantity,
    updateCartMetadata,
    getAddonsForItem,
    getAddonPriceForItem,
    clearItems,
    clearAddons,
    totalItems,
    cartTotal: paiditCartTotal,
    items,
    metadata,
  };
};
