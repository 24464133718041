import * as React from 'react';
import Card from '@mui/material/Card';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

interface Props {
  retry: () => Promise<any>;
}

const ErrorRetry = function ({ retry }: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const performRetry = async () => {
    setLoading(true);
    await retry();
    setLoading(false);
  };

  return (
    <Card sx={{ textAlign: 'center', m: 4, position: 'relative' }}>
      <CardContent>
        <Typography>
          <ErrorOutlineIcon />
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {t('common.error.title')}
        </Typography>
        <Button disabled={loading} onClick={() => performRetry()} variant="outlined">
          {loading ? <CircularProgress sx={{ mx: 3 }} size={20} /> : t('common.error.cta')}
        </Button>
      </CardContent>
    </Card>
  );
};

export default ErrorRetry;
