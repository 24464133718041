import { useRouter } from 'next/router';
import { ItemIngestionType } from 'src/graphql/generated/operations';

const useIngestionType = (): ItemIngestionType => {
  const router = useRouter();
  const { takeaway } = router.query;

  return takeaway === 'true' ? ItemIngestionType.Takeaway : ItemIngestionType.InHouse;
};

export default useIngestionType;
