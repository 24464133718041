import * as React from 'react';
import useClientData from 'src/hooks/client-data';
import { useGlobalState } from 'src/hooks/global-state';
import ErrorRetry from './error-retry';

/**
 * A crucial part of this app - this component acts as a guard to every child of it, ensuring
 * that there is always a client entity available before any child renders. Sets several variables
 * in the global state that other hooks depend on.
 */
const WithClient = function withClient({ children }: { children: React.ReactChild }) {
  const { error, refetch } = useClientData();
  const [ingestion] = useGlobalState('ingestion');
  const [client] = useGlobalState('client');
  const [activeCategory] = useGlobalState('activeCategory');
  const [availableCategories] = useGlobalState('availableCategories');
  const readyToRender = !!(client && activeCategory && ingestion && availableCategories);

  return (
    <>
      {/* Ensures that anything wrapped by WithClient always has a client, category, and ingestion available through global state */}
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      {readyToRender && <>{children}</>}
      {error && <ErrorRetry retry={refetch} />}
    </>
  );
};

export default WithClient;
