import { useCallback } from 'react';
import { usePaiditCart } from './paidit-cart';

/**
 * Comments are stored as metadata in the cart, and will only work where the cart is available.
 */
const useComments = () => {
  const { metadata, updateCartMetadata, setComment, deleteComment } = usePaiditCart();
  const allComments = { ...metadata?.comments };

  const getComment = useCallback(
    (itemId: string) => {
      const comment = allComments[itemId];
      return comment;
    },
    [allComments],
  );

  const clearComments = useCallback(() => {
    const comments = {};
    updateCartMetadata({ comments });
  }, []);

  return { getComment, setComment, deleteComment, clearComments, allComments };
};

export default useComments;
