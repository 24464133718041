import { ItemIngestionType } from 'src/graphql/generated/operations';
import { useGlobalState } from './global-state';
import useIngestionType from './ingestion-type';
import { usePaiditCart } from './paidit-cart';
import useTableNumberLabel from './table-number-label';

/**
 * The table number is stored as metadata in the cart, and relies on the cart being available.
 */
const useTableNumber = () => {
  const { metadata, updateCartMetadata } = usePaiditCart();
  const [client] = useGlobalState('client');
  const ingestionType = useIngestionType();
  const tableNumber = metadata?.table || '';
  const isInHouse = ingestionType === ItemIngestionType.InHouse;
  const tableNumberValid = !!tableNumber;
  const { deliveryTerms } = client;
  const hasDeliverDelivery = deliveryTerms.includes('DELIVER');
  const { queryParameter } = useTableNumberLabel();
  // The table field should be required if the queryparameter for setting a label
  //  is provided regardless of whether the current configuration/setting requires it.
  const tableNumberRequired = !!queryParameter || (isInHouse && hasDeliverDelivery);

  const changeTableNumber = (table: string) => {
    updateCartMetadata({ table });
  };

  const clearTableNumber = () => {
    updateCartMetadata({ table: undefined });
  };

  return {
    changeTableNumber,
    clearTableNumber,
    tableNumber,
    tableNumberValid,
    tableNumberRequired,
  };
};

export default useTableNumber;
