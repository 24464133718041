import React from 'react';
import { CartProvider } from 'react-use-cart';
import { usePaiditCartCleaner } from 'src/hooks/paidit-cart-cleaner';
import useClientkey from '../hooks/client-key';
import useIngestionType from '../hooks/ingestion-type';
import WithTableNumberParameter from './with-table-param';

// This internal components runs a single hook on entry to clear the cart if it's
// outdated. We need to put this in a separate component to ensure that the cart exists
// when this component is mounted.
const WithCartGuard = function ({ children }: { children: React.ReactElement }) {
  const { clearOutdatedCart } = usePaiditCartCleaner();

  React.useEffect(clearOutdatedCart, []);

  return children;
};

const WithCart = function ({ children }: { children: React.ReactElement }) {
  const clientKey = useClientkey();
  const ingestionType = useIngestionType();
  const cartId = `${clientKey}-${ingestionType}`;

  return (
    !!clientKey && (
      <CartProvider id={cartId}>
        <WithCartGuard>
          <WithTableNumberParameter>{children}</WithTableNumberParameter>
        </WithCartGuard>
      </CartProvider>
    )
  );
};

export default WithCart;
