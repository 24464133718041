import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import useTableLabel from './table-label';

export interface IUseTableNumberLabel {
  queryParameter?: string;
  label: string;
  error: string;
}

const useTableNumberLabel = (): IUseTableNumberLabel => {
  const { query } = useRouter();
  const { t } = useTranslation();
  const exceptionlabels = useTableLabel();
  const defaultLabel = exceptionlabels.labelTranslation;
  const defaultError = exceptionlabels.errorTranslation;

  if (query.tableNumberLabel) {
    const firstParameter =
      typeof query.tableNumberLabel === 'string'
        ? query.tableNumberLabel
        : query.tableNumberLabel[0];
    const customError = t('checkout.form.tableErrorCustom', { label: firstParameter });

    return {
      queryParameter: firstParameter,
      label: firstParameter,
      error: customError,
    };
  }
  return {
    queryParameter: undefined,
    label: defaultLabel,
    error: defaultError,
  };
};

export default useTableNumberLabel;
