import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import useTableNumber from 'src/hooks/table-number';

/**
 * This hook looks for the tableNumber query param, and if present sets that table number
 * and removes the query parameter shallowly (without page reload).
 *
 * It has to run after the cart is initialised since it depends on being able to manipulate cart metadata.
 */
const useTableNumberFromQuery = () => {
  const router = useRouter();
  const { changeTableNumber } = useTableNumber();
  const { pathname, query } = router;

  useEffect(() => {
    if (query.tableNumber) {
      changeTableNumber(query.tableNumber as string);
      delete router.query.tableNumber;
      router.replace({ pathname, query }, undefined, { shallow: true });
    }
  }, [query]);
};

const WithTableNumberParameter = function ({ children }: { children: React.ReactElement }) {
  useTableNumberFromQuery();

  return children;
};

export default WithTableNumberParameter;
