import * as React from 'react';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import detector from 'i18next-browser-languagedetector';

import en from 'public/translations/en.json';
import se from 'public/translations/se.json';

const resources = {
  en: {
    translation: en,
  },
  se: {
    translation: se,
  },
};

// i18next.use(detector).use(initReactI18next).init({
i18next.use(initReactI18next).init({
  fallbackLng: 'se',
  debug: false,
  resources,
});

const WithI18n = ({ children }: { children: React.ReactElement }) => children;

export default WithI18n;
