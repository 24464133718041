import { useRouter } from 'next/router';

/**
 * The categories parameter is an optional parameter that designates which categories that should show in the menu.
 * @returns an array of category identifiers
 */
const useCategoriesParameter = (): number[] => {
  const router = useRouter();
  const categoriesParameter = router.query?.categories || [];

  const categoriesStringArray =
    typeof categoriesParameter === 'string' ? categoriesParameter.split(',') : categoriesParameter;

  return categoriesStringArray.map((c) => parseInt(c, 10));
};

export default useCategoriesParameter;
