import { createGlobalState } from 'react-hooks-global-state';
import { CategoryType, Client, IngestionsType } from 'src/graphql/generated/operations';

/**
 * The global state is available as long as a client _can_ be fetched.
 * It should be computable entirely from fields on the client and should
 * contain only items that there can be only one of in the app at any given time.
 *
 * Technically, the client and ingestion can be undefined, but only
 * before the client has been fetched. However, we lie about that in the types
 * to avoid excessive use of '?' in every component that uses the global state.
 */
export interface GlobalState {
  // The active client.
  client: Client;
  // The active ingestion.
  ingestion: IngestionsType;
  // The active category.
  activeCategory: CategoryType;
  // The available categories - derived from the client.
  availableCategories: CategoryType[];
}

// unfortunately we have to "lie" about the initial state since no part
// of the app is valid unless client, ingestion, activeCategory fields on the state exists.
// When loading the app those fields will briefly be undefined, and we want to avoid
// having nullchecks for those fields _everywhere_.
const initialState: any = {
  client: undefined,
  ingestion: undefined,
  activeCategory: undefined,
  availableCategories: [],
};

export const { useGlobalState, setGlobalState, getGlobalState } =
  createGlobalState<GlobalState>(initialState);
