import { useTranslation } from 'react-i18next';
import { useGlobalState } from './global-state';

/**
 * The table label hook is responsible for providing a few custom labels, depending on currently active client.
 */
const useTableLabel = () => {
  const { t } = useTranslation();
  const [client] = useGlobalState('client');

  const specialLabelKey = `checkout.form.tableNumber.${client.name}`;
  const specialLabel = t(specialLabelKey);
  const defaultLabel = t('checkout.form.tableNumber.default');
  const hasSpecialLabel = specialLabelKey !== specialLabel;

  const specialErrorKey = `checkout.form.tableError.${client.name}`;
  const specialError = t(specialErrorKey);
  const defaultError = t('checkout.form.tableError.default');
  const hasSpecialError = specialErrorKey !== specialError;

  return {
    labelTranslation: hasSpecialLabel ? specialLabel : defaultLabel,
    errorTranslation: hasSpecialError ? specialError : defaultError,
  };
};

export default useTableLabel;
