/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Head from 'next/head';
import Container from '@mui/material/Container';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import NextNprogress from 'nextjs-progressbar';
import { useRouter } from 'next/router';
import WithCart from 'src/components/with-cart';
import getTheme from 'src/theme/custom-themes';
import getFont from 'src/theme/custom-fonts';
import useClientkey from 'src/hooks/client-key';
import WithClient from 'src/components/with-client';
import WithI18n from 'src/components/with-i18n';

const client = new ApolloClient({
  uri: '/api/graphql',
  cache: new InMemoryCache({
    typePolicies: {
      OrderItemsResponseType: {
        keyFields: false,
      },
    },
  }),
});

/**
 * The Application component is mounted on every page.
 */
const Application = function (props: AppProps) {
  const { Component, pageProps } = props;
  const clientKey = useClientkey();
  const font = getFont(clientKey);
  const theme = getTheme(clientKey);
  const router = useRouter();
  const isOnRoot = router.pathname === '/';

  return (
    <ThemeProvider theme={theme}>
      <WithI18n>
        <>
          <Head>
            <title>Paidit</title>
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width, viewport-fit=cover"
            />
          </Head>
          {font.url && <link href={font.url} rel="stylesheet" />}
          <CssBaseline />
          <ApolloProvider client={client}>
            <Container maxWidth="sm" disableGutters>
              <NextNprogress
                color={theme.palette.secondary.light}
                startPosition={0.1}
                stopDelayMs={200}
                height={3}
                options={{ easing: 'ease', speed: 200 }}
              />
              {/* If the current route is not the root route, block all rendering of children until a client is loaded. */}
              {!isOnRoot && (
                <WithClient>
                  <WithCart>
                    {/* @ts-ignore */}
                    <Component {...pageProps} />
                  </WithCart>
                </WithClient>
              )}
              {/* The root route is an exception in the app since it's the only page on which a client is not loaded. */}
              {/* @ts-ignore */}
              {isOnRoot && <Component {...pageProps} />}
            </Container>
          </ApolloProvider>
        </>
      </WithI18n>
    </ThemeProvider>
  );
};

export default Application;
