import { useRouter } from 'next/router';

/**
 * This hook is only intended to be used by components that are mounted before the client data is loaded.
 * For using actual client data - use the global state hook instead.
 * @returns string the client key
 */
const useClientKey = (): string => {
  const router = useRouter();
  const client = router.query?.client;

  return client as string;
};

export default useClientKey;
