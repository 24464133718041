import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import { cyan, grey, purple, brown, blue } from '@mui/material/colors';
import getFont from './custom-fonts';

interface Themes {
  [key: string]: Theme;
}

const baseTheme: ThemeOptions = {
  typography: {
    fontWeightRegular: 400,
    button: {
      textTransform: 'none',
    },
    fontFamily: getFont('').fontFamily,
  },
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: purple[600],
    },
  },
};

const demoTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: purple[500],
    },
  },
});

const olstugan = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    primary: {
      main: brown[900],
    },
  },
});

const themes: Themes = {
  surfsaloon: createTheme({
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: {
        main: cyan[400],
      },
    },
  }),
  tomtelandmora: createTheme({
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: {
        main: '#27782e',
      },
      secondary: {
        main: '#782771',
      },
    },
  }),
  barburger: createTheme({
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      mode: 'dark',
      primary: {
        main: grey[100],
      },
    },
  }),
  'olstugan-kalltorp': olstugan,
  'olstugan-molndal': olstugan,
  'olstugan-lejonet': olstugan,
  'olstugan-hornstull': olstugan,
  'olstugan-kville': olstugan,
  'olstugan-gamlestan': olstugan,
  'olstugan-langgatan': olstugan,
  'olstugan-wardshuset': olstugan,
  'olstugan-majorna': olstugan,
  'olstugan-sveavagen': olstugan,
  'olstugan-kungsholmen': olstugan,
  'olstugan-johanneberg': olstugan,
  'demo-categories': demoTheme,
  'demo-lunch': demoTheme,
  demo: demoTheme,
};

const getTheme = (clientId: string): Theme =>
  themes[clientId] ? themes[clientId] : createTheme(baseTheme);

export default getTheme;
