import dayjs from 'dayjs';
import useComments from './comments';
import { usePaiditCart } from './paidit-cart';
import useTableNumber from './table-number';

export const HOURS_BEFORE_CLEANUP = 18;

/**
 * The paidit cart cleaner contains utility functions that use other hooks to clean the cart/user state.
 */
export const usePaiditCartCleaner = () => {
  const { clearComments } = useComments();
  const { clearTableNumber } = useTableNumber();
  const { metadata, clearItems, clearAddons } = usePaiditCart();

  /**
   * Clears the cart if outdated. An outdated cart has not been changed in a period of time
   * long enough that the user is most likely not interested in keeping the cart.
   * Clearing the cart this way also clears the comments, and table number.
   * This function should be used sparsely, and most likely only on application entry.
   */
  const clearOutdatedCart = () => {
    const lastModified = metadata?.lastModified ? dayjs(metadata.lastModified) : dayjs();
    const limit = dayjs().subtract(HOURS_BEFORE_CLEANUP, 'hours');
    const limitPassed = limit.isAfter(lastModified);

    if (limitPassed) {
      clearItems();
      clearAddons();
      clearComments();
      clearTableNumber();
    }
  };

  /**
   * Clears a cart after successful purchase.
   * Removes items, addons, comments, but keeps table number.
   */
  const clearPurchasedCart = () => {
    clearItems();
    clearAddons();
    clearComments();
  };

  return {
    clearOutdatedCart,
    clearPurchasedCart,
  };
};
