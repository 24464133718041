import React from 'react';
import { useClientLazyQuery } from 'src/graphql/generated/operations';
import useClientkey from 'src/hooks/client-key';
import { useGlobalState } from 'src/hooks/global-state';
import useIngestionType from 'src/hooks/ingestion-type';
import useCategoriesParameter from './categories-parameter';

/**
 * The useClientData hook sets client data to the global state.
 * This hook needs to run first to view any page that requires client info (essentially all pages).
 */
const useClientData = () => {
  const clientKey = useClientkey();
  const categoriesParameter = useCategoriesParameter();
  const ingestionType = useIngestionType();
  const [, setClient] = useGlobalState('client');
  const [, setActiveCategory] = useGlobalState('activeCategory');
  const [, setAvailableCategories] = useGlobalState('availableCategories');
  const [, setIngestion] = useGlobalState('ingestion');
  const [queryForClient, { data, error, refetch }] = useClientLazyQuery({
    variables: {
      ingestion: ingestionType,
      client: clientKey,
    },
  });

  // the client query only runs when the client key changes
  // which should only happen on initial load.
  React.useEffect(() => {
    if (clientKey) {
      queryForClient();
    }
  }, [clientKey]);

  // When clientData changes we update the ingestion and active category.
  // This should only happen on initial load for a client.
  React.useEffect(() => {
    if (data) {
      const ingestion = data?.client?.ingestions?.find((i) => i.type === ingestionType);
      const categories = data?.client.categories || [];
      const categoriesFilteredByParameter = categories.filter(
        (c) => !categoriesParameter.length || categoriesParameter.includes(c.category),
      );
      const firstOpenCategory =
        categoriesFilteredByParameter.find((cat) => cat.active) || categoriesFilteredByParameter[0];

      setIngestion(ingestion);
      setActiveCategory(firstOpenCategory);
      setClient(data.client);
      setAvailableCategories(categoriesFilteredByParameter);
    }
  }, [data, ingestionType]);

  return {
    error,
    refetch,
  };
};

export default useClientData;
